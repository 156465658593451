<template>
  <b-modal
    id="StatusModal"
    ref="StatusModal"
    size="sm"
    centered
    hide-header
    hide-footer
    hide-backdrop
    @shown="stopPropModal"
    @hid="defaultValue"
  >
    <b-form-group
      class="mb-2"
      label="โน๊ตนัดหมาย:"
      description="บันทึกรายละเอียดเช่น เบอร์ถูกระงับ, ยังไม่แน่ใจ เป็นต้น"
    >
      <b-form-textarea
        class="mb-0"
        v-model="note"
        trim
        placeholder="รายละเอียดเพิ่มเติม..."
        rows="3"
      ></b-form-textarea>
    </b-form-group>

    <b-table-simple hover small class="m-0">
      <b-tr v-for="status in statusList" :key="status.code">
        <b-td
          class="text-center border-white rounded"
          :style="{
            backgroundColor: status.desc,
            color: 'white',
            cursor: 'pointer',
          }"
          @click="updateAppointment(status.val)"
          >{{ status.label }}</b-td
        >
      </b-tr>
    </b-table-simple>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "StatusModal",
  components: {
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      appointment: null,
      note: "",
    };
  },
  computed: {
    ...mapGetters({
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getBranchInfo: "moduleUser/getBranchInfo",
      getQueueAppointmentBy: "moduleAppointment/getQueueAppointmentBy",
      getDoctorsAppointmentBy: "moduleAppointment/getDoctorsAppointmentBy",
    }),
    statusList() {
      return this.getAppointmentStatusList.filter(
        item => ![5, 6, 7, 8, 11, 12, 13, 21, 22, 23].includes(item.val)
      );
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      confirmationQueueAppointment:
        "moduleAppointment/confirmationQueueAppointment",
    }),

    show(appointment) {
      this.appointment =
        this.getQueueAppointmentBy(appointment.id, "id") ||
        this.getDoctorsAppointmentBy(appointment.id, "id");
      this.note = appointment.extendedProps.note;
      this.$bvModal.show("StatusModal");
    },
    stopPropModal() {
      document
        .getElementById("StatusModal___BV_modal_outer_")
        .addEventListener("click", e => e.stopPropagation());
    },
    // changeStatus(status) {
    //   this.$emit("isLoading", true);
    //   this.confirmationQueueAppointment({
    //     statusId: status.val,
    //     id: this.appointment.id,
    //     clinicUrl: this.$route.params.clinicUrl,
    //     branchUrl: this.$route.params.branchUrl,
    //   })
    //     .then((res) => {
    //       if (res.status == "200") {
    //         this.$bvModal.hide("StatusModal");
    //         this.$emit("hidePopover");
    //       }
    //       this.$emit("isLoading", false);
    //     })
    //     .catch(() => {
    //       this.$emit("isLoading", false);
    //       this.$parent.showDialogToast("warning", "ไม่สามารถเปลี่ยนสถานะได้");
    //     });
    // },
    async updateAppointment(statusId) {
      if (this.note != this.appointment.extendedProps.note) {
        this.$refs.Dialog.showAlertConfirm(
          'มีการเปลี่ยนแปลง "โน๊ตนัดหมาย"',
          'คุณต้องการบันทึก "โน๊ตนัดหมาย"?'
        ).then(async res => {
          if (res.isConfirmed) {
            await this.putAppointment(statusId);
          }
        });
        document
          .querySelector(".swal2-container")
          .addEventListener("click", e => e.stopPropagation());
      } else if (statusId != this.appointment.extendedProps.statusId) {
        await this.putAppointment(statusId);
      }
      this.$emit("hidePopover");
      this.$bvModal.hide("StatusModal");
    },
    async putAppointment(statusId) {
      this.$emit("isLoading", true);
      let eventData = {
        id: this.appointment.id,
        startDt: this.appointment.start,
        endDt: this.appointment.end,
        roomId: parseInt(this.appointment.resourceId),
        aptTreatItems: this.appointment.extendedProps.treatmentList,
        doctorUid: this.appointment.extendedProps.doctor,
        patientId: this.appointment.extendedProps.patient.id,
        note: this.note,
        statusId,
      };
      this.$store
        .dispatch("moduleAppointment/updateQueueAppointment", {
          event: eventData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(res => {
          if (res.status == "200") {
            console.log("-- Update event completed --");
          }
        })
        .finally(() => {
          this.$emit("isLoading", false);
        });
    },

    defaultValue() {
      this.appointment = null;
      this.note = "";
    },
  },
};
</script>

<style scoped>
#StatusModal___BV_modal_outer_ {
  z-index: 1060 !important;
}
</style>